'use strict';

import Api from 'services/api';
import Dispatcher from 'dispatcher/TngAppDispatcher';
import constants from 'constants/appConstants';
import * as authContext from 'services/authContext';

var AuthActionCreators = {

    pageMounted() {
        authContext.checkForToken();
    },

    pageReady(isNewSignIn) {
        let user = null;
        const profile = authContext.getCurrentUser();
        if (profile && authContext.getToken) {
            user = {
                profile,
            };
        } 

        Dispatcher.handleAction({
            actionType: constants.actions.PAGE_LOADED,
            data: {user},
        });

        if (isNewSignIn) {
            Dispatcher.handleAction({
                actionType: constants.actions.LOGIN,
            });
        }
    },

    signUp(token) {
        authContext.signUp(token);            
    },
    
    login() {
        authContext.login(); 
    },

    logout() {
        authContext.logOut();       
    },

    getUserIdp(userIdentifier) {
        Api.AUTH.getUserIdp(userIdentifier);
    },

    signInError() {
        Dispatcher.handleAction({
            actionType: constants.actions.PAGE_LOADED,
            data: {signInError: true},
        });

    },
};

export default AuthActionCreators; 
